import { columns } from "./config/index";
import { IMApi } from "@/api";
export default {
  data: function data() {
    return {
      columns: columns,
      page: 1,
      size: 10,
      total: 0,
      dataSource: [{}],
      statusList: ['机审中', '机审未通过', '人工审核', '人工审核未通过', '通过', '已删除'],
      loading: false,
      visibleDel: false,
      delItem: {},
      visibleDetail: false,
      detailForm: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        switch (key) {
          case "type":
            return [{
              id: '',
              name: '所有类型'
            }, {
              id: 1,
              name: '垃圾信息'
            }, {
              id: 2,
              name: '暴力'
            }, {
              id: 3,
              name: '色情'
            }, {
              id: 4,
              name: '虐待儿童'
            }, {
              id: 5,
              name: '欺诈'
            }, {
              id: 7,
              name: '版权'
            }, {
              id: 8,
              name: '其他'
            }];
        }
      };
    }
  },
  methods: {
    handleButton: function handleButton(Type, item) {
      switch (Type) {
        case 'view':
          this.visibleDetail = true;
          this.detailForm = item;
          break;
        case 'del':
          this.visibleDel = true;
          this.delItem = item;
          break;
        default:
          break;
      }
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    },
    getList: function getList() {},
    comfirmDel: function comfirmDel() {}
  }
};